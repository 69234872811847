'use client'

import Box from '@mui/joy/Box'
import { IconVideoView } from 'src/assets/icons/IconVideoView'
import UIText from '@components/ui/UIText'
import { pxToRem } from '@ui/style/muiTheme'
import ContentBadge from '@components/content/ContentBadge'
import color from '@ui/style/color.theme'
import UILink from '@components/ui/UILink'
import { ContentProps, ThumbnailConfigProps } from '..'
import UIImage from '@components/ui/UIImage'

interface ThumbnailProps
	extends ThumbnailConfigProps,
		Pick<
			ContentProps,
			| 'src'
			| 'alt'
			| 'duration'
			| 'showLiveBadge'
			| 'liveTagTypeCode'
			| 'showVideoViewIcon'
			| 'href'
			| 'query'
		> {}

/**
 *
 * @TODO
 * - img태그를 이미지 컴포넌트로 수정
 */
const Thumbnail = ({
	src,
	alt,
	thumbWidth = 'auto',
	thumbHeight = 'auto',
	showVideoViewIcon,
	duration,
	showLiveBadge,
	liveTagTypeCode,
	badgeInset = { xs: pxToRem(8), lg: pxToRem(8) },
	thumbSx,
	thumbRatio,
	radius,
	href,
	query,
	isContain,
}: ThumbnailProps) => {
	return (
		<Box
			position="relative"
			width={thumbWidth}
			height={thumbHeight}
			flexShrink="0"
			borderRadius={radius}
			sx={{ ...thumbSx, isolation: 'isolate' }}
			overflow={radius ? 'hidden' : 'initial'}
			className="thumb"
			{...(href && { href, query, component: UILink, display: 'block' })}
		>
			{/* Image */}
			<UIImage
				ratio={thumbRatio}
				isContain={isContain}
				src={src}
				alt={alt}
				className="thumb-img"
				sx={{
					willChange: 'transform',
					'.MuiAspectRatio-content': {
						paddingBottom: thumbHeight,
					},
				}}
			/>
			{/* Live Badge */}
			{showLiveBadge && liveTagTypeCode && (
				<Box
					position="absolute"
					bottom={badgeInset}
					zIndex={2}
					right={badgeInset}
					className="thumb-liveBadge"
					sx={{
						willChange: 'transform',
					}}
				>
					<ContentBadge contentType="LIVE" liveTagTypeCode={liveTagTypeCode} />
				</Box>
			)}
			{/* Video View Icon */}
			{showVideoViewIcon && (
				<Box
					position="absolute"
					top={badgeInset}
					right={badgeInset}
					className="thumb-videoViewIcon"
					sx={{
						willChange: 'transform',
					}}
				>
					<IconVideoView />
				</Box>
			)}
			{/* Duration */}
			{duration && (
				<Box
					position="absolute"
					bottom={badgeInset}
					right={badgeInset}
					className="thumb-duration"
					sx={{
						willChange: 'transform',
					}}
				>
					<UIText
						sx={{
							display: 'block',
							padding: `${pxToRem(4)} ${pxToRem(8)}`,
							background: color.shade,
							borderRadius: '100px',
							color: color.colWhite,
							fontSize: pxToRem(13),
							fontWeight: 500,
							lineHeight: pxToRem(14),
						}}
					>
						{duration}
					</UIText>
				</Box>
			)}
		</Box>
	)
}

export default Thumbnail
